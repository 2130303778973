<template>
  <div class="shipments-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Awaiting Actions')}}</h2>
      </div>
    </div>
    <template v-if="awaitingFlag">
      <shipblu-table
        multiple
        v-model="selected"
        :sst="true"
        :data="ordersAwaitingActions"
        :max-items="maximumItems"
        pagination
        :tableLoader="tableLoader"
      >
        <template slot="thead">
          <shipblu-th>{{$t('Type')}}</shipblu-th>
          <shipblu-th>{{$t('Code / Tracking No.')}}</shipblu-th>
          <shipblu-th>{{$t('Details')}}</shipblu-th>
          <shipblu-th>{{$t('Action')}}</shipblu-th>
          <shipblu-th>{{$t('Outcome')}}</shipblu-th>
        </template>

        <template slot-scope="{ data }">

          <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
            :to="{ name: `${data[indextr].action_required.data.order_id ? 'head-of-fleet-orders-awaiting-actions-order-view' : data[indextr].action_required.data.seal_id ? 'head-of-fleet-seal-view' : 'head-of-fleet-supplies'}`, params: data[indextr].action_required.data.order_id ? { type: 'delivery-orders', orderID: data[indextr].action_required.data.order_id } : data[indextr].action_required.data.seal_id ? {warehouseID: $route.params.warehouseID, sealId: data[indextr].action_required.data.seal_id} : {warehouseID: $route.params.warehouseID}}">
            <shipblu-td :data="data[indextr].type">
              {{ data[indextr].action_required.data.order_id ? $t('Order') : data[indextr].action_required.data.seal_id ? $t('Master Bag') : $t('Supply Request') }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
              <p v-on:click.stop>{{ data[indextr].action_required.data.order_id ? data[indextr].action_required.data.tracking_number : data[indextr].action_required.data.seal_id ? data[indextr].action_required.data.code : '' }}</p>
            </shipblu-td>

            <shipblu-td :data="data[indextr].details">
              <p :key="key" v-for="(key, value) in data[indextr].action_required.data">
                <span v-if="!value.includes('_id') && !value.includes('tracking_number') && !value.includes('code')">{{$t(value)}}: {{key}}</span>
              </p>
            </shipblu-td>

            <shipblu-td :data="data[indextr].merchantName">
              {{ data[indextr].action_required.message }}
            </shipblu-td>

            <shipblu-td>
              <div @click="confirmOrder(data[indextr])" class="flex justify-center" v-on:click.stop>
                <span class="check-button flex items-center justify-center p-1">
                  <feather-icon
                    color="success"
                    icon="CheckIcon"
                    svgClasses="h-6 w-6"
                  />
                </span>
              </div>
            </shipblu-td>
          </shipblu-tr>
        </template>
      </shipblu-table>
      <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    </template>
    <div v-else class="text-center">
      <div>
        <img class="lg:w-5/12 md:w-4/5 sm:w-full xs:w-full" :src="successImage" alt="Checklist-img">
      </div>
      <div  class="mt-6 text-center">
        <span class="text-center items-center mr-32">
          <span class="font-normal lg:text-xl md:text-xl sm:text-lg xs:text-md block">
            {{ $t('You are all set. Thank you for calling our customers and making them happy') }} 
            <span class="lg:text-xl md:text-xl sm:text-xl xs:text-lg">&#128522;</span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import successImage from '@/assets/images/pages/success.svg'
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import common  from '@/assets/utils/common'
import i18nData from '../../i18n/i18nData.js'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      selected: [],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      offset: 0,
      successImage,
      ordersAwaitingActions: [],
      awaitingFlag: true,
      warehousesDic: {},
      tableLoader: false,
      warehouses: []
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadOrdersAwaitingActions()
    },
    '$route.params.warehouseID' () {
      this.loadOrdersAwaitingActions()
    }
  },
  methods: {
    formatWarehouses (warehouses) {
      this.warehouses = warehouses
      this.warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
      this.loadOrdersAwaitingActions()
    },
    confirmOrder (data) {
      sendRequest(false, false, this, `api/v1/warehouses/awaiting-actions/${data.id}/`, 'patch', {status: 'done'}, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadOrdersAwaitingActions()
        }
      )
    },
    loadOrdersAwaitingActions () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/warehouses/awaiting-actions/?offset=${this.offset}&limit=${this.maximumItems}&warehouse=${this.warehousesDic[this.$route.params.warehouseID]}&status=pending`, 'get', null, true, 
        (response) => {
          this.selected = []
          this.ordersAwaitingActions = response.data.results
          this.tableLoader = false
          this.awaitingFlag = response.data.count > 0
          this.totalRows = response.data.count
        }
      )
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    common.manageLoadWarehouses(this.formatWarehouses, this)
  }
}
</script>

<style lang="scss">
.con-pagination-table{
  display: none;
}
.check-button {
  color: #28C76F;
  // background: rgb(235, 233, 233);
  border: 1px solid #000;
  border-radius: 4px;
}
</style>
